/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IndexImport } from './routes/index'
import { Route as DashboardLayoutImport } from './routes/dashboard/_layout'
import { Route as DashboardLayoutUserInstancesImport } from './routes/dashboard/_layout/user/instances'
import { Route as DashboardLayoutInstanceInstanceImport } from './routes/dashboard/_layout/instance/$instance'
import { Route as DashboardLayoutAdminLayoutImport } from './routes/dashboard/_layout/admin/_layout'
import { Route as DashboardLayoutInstanceInstanceProxiesImport } from './routes/dashboard/_layout/instance/$instance/proxies'
import { Route as DashboardLayoutInstanceInstanceDiscoverImport } from './routes/dashboard/_layout/instance/$instance/discover'
import { Route as DashboardLayoutInstanceInstanceConsoleImport } from './routes/dashboard/_layout/instance/$instance/console'
import { Route as DashboardLayoutInstanceInstanceAccountsImport } from './routes/dashboard/_layout/instance/$instance/accounts'
import { Route as DashboardLayoutAdminLayoutOverviewImport } from './routes/dashboard/_layout/admin/_layout/overview'
import { Route as DashboardLayoutAdminLayoutConsoleImport } from './routes/dashboard/_layout/admin/_layout/console'
import { Route as DashboardLayoutInstanceInstanceSettingsNamespaceImport } from './routes/dashboard/_layout/instance/$instance/settings/$namespace'
import { Route as DashboardLayoutAdminLayoutSettingsNamespaceImport } from './routes/dashboard/_layout/admin/_layout/settings/$namespace'

// Create Virtual Routes

const DashboardImport = createFileRoute('/dashboard')()
const DashboardLayoutAdminImport = createFileRoute('/dashboard/_layout/admin')()

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const DashboardLayoutRoute = DashboardLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardLayoutAdminRoute = DashboardLayoutAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => DashboardLayoutRoute,
} as any)

const DashboardLayoutUserInstancesRoute =
  DashboardLayoutUserInstancesImport.update({
    id: '/user/instances',
    path: '/user/instances',
    getParentRoute: () => DashboardLayoutRoute,
  } as any)

const DashboardLayoutInstanceInstanceRoute =
  DashboardLayoutInstanceInstanceImport.update({
    id: '/instance/$instance',
    path: '/instance/$instance',
    getParentRoute: () => DashboardLayoutRoute,
  } as any)

const DashboardLayoutAdminLayoutRoute = DashboardLayoutAdminLayoutImport.update(
  {
    id: '/_layout',
    getParentRoute: () => DashboardLayoutAdminRoute,
  } as any,
)

const DashboardLayoutInstanceInstanceProxiesRoute =
  DashboardLayoutInstanceInstanceProxiesImport.update({
    id: '/proxies',
    path: '/proxies',
    getParentRoute: () => DashboardLayoutInstanceInstanceRoute,
  } as any)

const DashboardLayoutInstanceInstanceDiscoverRoute =
  DashboardLayoutInstanceInstanceDiscoverImport.update({
    id: '/discover',
    path: '/discover',
    getParentRoute: () => DashboardLayoutInstanceInstanceRoute,
  } as any)

const DashboardLayoutInstanceInstanceConsoleRoute =
  DashboardLayoutInstanceInstanceConsoleImport.update({
    id: '/console',
    path: '/console',
    getParentRoute: () => DashboardLayoutInstanceInstanceRoute,
  } as any)

const DashboardLayoutInstanceInstanceAccountsRoute =
  DashboardLayoutInstanceInstanceAccountsImport.update({
    id: '/accounts',
    path: '/accounts',
    getParentRoute: () => DashboardLayoutInstanceInstanceRoute,
  } as any)

const DashboardLayoutAdminLayoutOverviewRoute =
  DashboardLayoutAdminLayoutOverviewImport.update({
    id: '/overview',
    path: '/overview',
    getParentRoute: () => DashboardLayoutAdminLayoutRoute,
  } as any)

const DashboardLayoutAdminLayoutConsoleRoute =
  DashboardLayoutAdminLayoutConsoleImport.update({
    id: '/console',
    path: '/console',
    getParentRoute: () => DashboardLayoutAdminLayoutRoute,
  } as any)

const DashboardLayoutInstanceInstanceSettingsNamespaceRoute =
  DashboardLayoutInstanceInstanceSettingsNamespaceImport.update({
    id: '/settings/$namespace',
    path: '/settings/$namespace',
    getParentRoute: () => DashboardLayoutInstanceInstanceRoute,
  } as any)

const DashboardLayoutAdminLayoutSettingsNamespaceRoute =
  DashboardLayoutAdminLayoutSettingsNamespaceImport.update({
    id: '/settings/$namespace',
    path: '/settings/$namespace',
    getParentRoute: () => DashboardLayoutAdminLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/_layout': {
      id: '/dashboard/_layout'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardLayoutImport
      parentRoute: typeof DashboardRoute
    }
    '/dashboard/_layout/admin': {
      id: '/dashboard/_layout/admin'
      path: '/admin'
      fullPath: '/dashboard/admin'
      preLoaderRoute: typeof DashboardLayoutAdminImport
      parentRoute: typeof DashboardLayoutImport
    }
    '/dashboard/_layout/admin/_layout': {
      id: '/dashboard/_layout/admin/_layout'
      path: '/admin'
      fullPath: '/dashboard/admin'
      preLoaderRoute: typeof DashboardLayoutAdminLayoutImport
      parentRoute: typeof DashboardLayoutAdminRoute
    }
    '/dashboard/_layout/instance/$instance': {
      id: '/dashboard/_layout/instance/$instance'
      path: '/instance/$instance'
      fullPath: '/dashboard/instance/$instance'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceImport
      parentRoute: typeof DashboardLayoutImport
    }
    '/dashboard/_layout/user/instances': {
      id: '/dashboard/_layout/user/instances'
      path: '/user/instances'
      fullPath: '/dashboard/user/instances'
      preLoaderRoute: typeof DashboardLayoutUserInstancesImport
      parentRoute: typeof DashboardLayoutImport
    }
    '/dashboard/_layout/admin/_layout/console': {
      id: '/dashboard/_layout/admin/_layout/console'
      path: '/console'
      fullPath: '/dashboard/admin/console'
      preLoaderRoute: typeof DashboardLayoutAdminLayoutConsoleImport
      parentRoute: typeof DashboardLayoutAdminLayoutImport
    }
    '/dashboard/_layout/admin/_layout/overview': {
      id: '/dashboard/_layout/admin/_layout/overview'
      path: '/overview'
      fullPath: '/dashboard/admin/overview'
      preLoaderRoute: typeof DashboardLayoutAdminLayoutOverviewImport
      parentRoute: typeof DashboardLayoutAdminLayoutImport
    }
    '/dashboard/_layout/instance/$instance/accounts': {
      id: '/dashboard/_layout/instance/$instance/accounts'
      path: '/accounts'
      fullPath: '/dashboard/instance/$instance/accounts'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceAccountsImport
      parentRoute: typeof DashboardLayoutInstanceInstanceImport
    }
    '/dashboard/_layout/instance/$instance/console': {
      id: '/dashboard/_layout/instance/$instance/console'
      path: '/console'
      fullPath: '/dashboard/instance/$instance/console'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceConsoleImport
      parentRoute: typeof DashboardLayoutInstanceInstanceImport
    }
    '/dashboard/_layout/instance/$instance/discover': {
      id: '/dashboard/_layout/instance/$instance/discover'
      path: '/discover'
      fullPath: '/dashboard/instance/$instance/discover'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceDiscoverImport
      parentRoute: typeof DashboardLayoutInstanceInstanceImport
    }
    '/dashboard/_layout/instance/$instance/proxies': {
      id: '/dashboard/_layout/instance/$instance/proxies'
      path: '/proxies'
      fullPath: '/dashboard/instance/$instance/proxies'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceProxiesImport
      parentRoute: typeof DashboardLayoutInstanceInstanceImport
    }
    '/dashboard/_layout/admin/_layout/settings/$namespace': {
      id: '/dashboard/_layout/admin/_layout/settings/$namespace'
      path: '/settings/$namespace'
      fullPath: '/dashboard/admin/settings/$namespace'
      preLoaderRoute: typeof DashboardLayoutAdminLayoutSettingsNamespaceImport
      parentRoute: typeof DashboardLayoutAdminLayoutImport
    }
    '/dashboard/_layout/instance/$instance/settings/$namespace': {
      id: '/dashboard/_layout/instance/$instance/settings/$namespace'
      path: '/settings/$namespace'
      fullPath: '/dashboard/instance/$instance/settings/$namespace'
      preLoaderRoute: typeof DashboardLayoutInstanceInstanceSettingsNamespaceImport
      parentRoute: typeof DashboardLayoutInstanceInstanceImport
    }
  }
}

// Create and export the route tree

interface DashboardLayoutAdminLayoutRouteChildren {
  DashboardLayoutAdminLayoutConsoleRoute: typeof DashboardLayoutAdminLayoutConsoleRoute
  DashboardLayoutAdminLayoutOverviewRoute: typeof DashboardLayoutAdminLayoutOverviewRoute
  DashboardLayoutAdminLayoutSettingsNamespaceRoute: typeof DashboardLayoutAdminLayoutSettingsNamespaceRoute
}

const DashboardLayoutAdminLayoutRouteChildren: DashboardLayoutAdminLayoutRouteChildren =
  {
    DashboardLayoutAdminLayoutConsoleRoute:
      DashboardLayoutAdminLayoutConsoleRoute,
    DashboardLayoutAdminLayoutOverviewRoute:
      DashboardLayoutAdminLayoutOverviewRoute,
    DashboardLayoutAdminLayoutSettingsNamespaceRoute:
      DashboardLayoutAdminLayoutSettingsNamespaceRoute,
  }

const DashboardLayoutAdminLayoutRouteWithChildren =
  DashboardLayoutAdminLayoutRoute._addFileChildren(
    DashboardLayoutAdminLayoutRouteChildren,
  )

interface DashboardLayoutAdminRouteChildren {
  DashboardLayoutAdminLayoutRoute: typeof DashboardLayoutAdminLayoutRouteWithChildren
}

const DashboardLayoutAdminRouteChildren: DashboardLayoutAdminRouteChildren = {
  DashboardLayoutAdminLayoutRoute: DashboardLayoutAdminLayoutRouteWithChildren,
}

const DashboardLayoutAdminRouteWithChildren =
  DashboardLayoutAdminRoute._addFileChildren(DashboardLayoutAdminRouteChildren)

interface DashboardLayoutInstanceInstanceRouteChildren {
  DashboardLayoutInstanceInstanceAccountsRoute: typeof DashboardLayoutInstanceInstanceAccountsRoute
  DashboardLayoutInstanceInstanceConsoleRoute: typeof DashboardLayoutInstanceInstanceConsoleRoute
  DashboardLayoutInstanceInstanceDiscoverRoute: typeof DashboardLayoutInstanceInstanceDiscoverRoute
  DashboardLayoutInstanceInstanceProxiesRoute: typeof DashboardLayoutInstanceInstanceProxiesRoute
  DashboardLayoutInstanceInstanceSettingsNamespaceRoute: typeof DashboardLayoutInstanceInstanceSettingsNamespaceRoute
}

const DashboardLayoutInstanceInstanceRouteChildren: DashboardLayoutInstanceInstanceRouteChildren =
  {
    DashboardLayoutInstanceInstanceAccountsRoute:
      DashboardLayoutInstanceInstanceAccountsRoute,
    DashboardLayoutInstanceInstanceConsoleRoute:
      DashboardLayoutInstanceInstanceConsoleRoute,
    DashboardLayoutInstanceInstanceDiscoverRoute:
      DashboardLayoutInstanceInstanceDiscoverRoute,
    DashboardLayoutInstanceInstanceProxiesRoute:
      DashboardLayoutInstanceInstanceProxiesRoute,
    DashboardLayoutInstanceInstanceSettingsNamespaceRoute:
      DashboardLayoutInstanceInstanceSettingsNamespaceRoute,
  }

const DashboardLayoutInstanceInstanceRouteWithChildren =
  DashboardLayoutInstanceInstanceRoute._addFileChildren(
    DashboardLayoutInstanceInstanceRouteChildren,
  )

interface DashboardLayoutRouteChildren {
  DashboardLayoutAdminRoute: typeof DashboardLayoutAdminRouteWithChildren
  DashboardLayoutInstanceInstanceRoute: typeof DashboardLayoutInstanceInstanceRouteWithChildren
  DashboardLayoutUserInstancesRoute: typeof DashboardLayoutUserInstancesRoute
}

const DashboardLayoutRouteChildren: DashboardLayoutRouteChildren = {
  DashboardLayoutAdminRoute: DashboardLayoutAdminRouteWithChildren,
  DashboardLayoutInstanceInstanceRoute:
    DashboardLayoutInstanceInstanceRouteWithChildren,
  DashboardLayoutUserInstancesRoute: DashboardLayoutUserInstancesRoute,
}

const DashboardLayoutRouteWithChildren = DashboardLayoutRoute._addFileChildren(
  DashboardLayoutRouteChildren,
)

interface DashboardRouteChildren {
  DashboardLayoutRoute: typeof DashboardLayoutRouteWithChildren
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardLayoutRoute: DashboardLayoutRouteWithChildren,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardLayoutRouteWithChildren
  '/dashboard/admin': typeof DashboardLayoutAdminLayoutRouteWithChildren
  '/dashboard/instance/$instance': typeof DashboardLayoutInstanceInstanceRouteWithChildren
  '/dashboard/user/instances': typeof DashboardLayoutUserInstancesRoute
  '/dashboard/admin/console': typeof DashboardLayoutAdminLayoutConsoleRoute
  '/dashboard/admin/overview': typeof DashboardLayoutAdminLayoutOverviewRoute
  '/dashboard/instance/$instance/accounts': typeof DashboardLayoutInstanceInstanceAccountsRoute
  '/dashboard/instance/$instance/console': typeof DashboardLayoutInstanceInstanceConsoleRoute
  '/dashboard/instance/$instance/discover': typeof DashboardLayoutInstanceInstanceDiscoverRoute
  '/dashboard/instance/$instance/proxies': typeof DashboardLayoutInstanceInstanceProxiesRoute
  '/dashboard/admin/settings/$namespace': typeof DashboardLayoutAdminLayoutSettingsNamespaceRoute
  '/dashboard/instance/$instance/settings/$namespace': typeof DashboardLayoutInstanceInstanceSettingsNamespaceRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardLayoutRouteWithChildren
  '/dashboard/admin': typeof DashboardLayoutAdminLayoutRouteWithChildren
  '/dashboard/instance/$instance': typeof DashboardLayoutInstanceInstanceRouteWithChildren
  '/dashboard/user/instances': typeof DashboardLayoutUserInstancesRoute
  '/dashboard/admin/console': typeof DashboardLayoutAdminLayoutConsoleRoute
  '/dashboard/admin/overview': typeof DashboardLayoutAdminLayoutOverviewRoute
  '/dashboard/instance/$instance/accounts': typeof DashboardLayoutInstanceInstanceAccountsRoute
  '/dashboard/instance/$instance/console': typeof DashboardLayoutInstanceInstanceConsoleRoute
  '/dashboard/instance/$instance/discover': typeof DashboardLayoutInstanceInstanceDiscoverRoute
  '/dashboard/instance/$instance/proxies': typeof DashboardLayoutInstanceInstanceProxiesRoute
  '/dashboard/admin/settings/$namespace': typeof DashboardLayoutAdminLayoutSettingsNamespaceRoute
  '/dashboard/instance/$instance/settings/$namespace': typeof DashboardLayoutInstanceInstanceSettingsNamespaceRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/dashboard/_layout': typeof DashboardLayoutRouteWithChildren
  '/dashboard/_layout/admin': typeof DashboardLayoutAdminRouteWithChildren
  '/dashboard/_layout/admin/_layout': typeof DashboardLayoutAdminLayoutRouteWithChildren
  '/dashboard/_layout/instance/$instance': typeof DashboardLayoutInstanceInstanceRouteWithChildren
  '/dashboard/_layout/user/instances': typeof DashboardLayoutUserInstancesRoute
  '/dashboard/_layout/admin/_layout/console': typeof DashboardLayoutAdminLayoutConsoleRoute
  '/dashboard/_layout/admin/_layout/overview': typeof DashboardLayoutAdminLayoutOverviewRoute
  '/dashboard/_layout/instance/$instance/accounts': typeof DashboardLayoutInstanceInstanceAccountsRoute
  '/dashboard/_layout/instance/$instance/console': typeof DashboardLayoutInstanceInstanceConsoleRoute
  '/dashboard/_layout/instance/$instance/discover': typeof DashboardLayoutInstanceInstanceDiscoverRoute
  '/dashboard/_layout/instance/$instance/proxies': typeof DashboardLayoutInstanceInstanceProxiesRoute
  '/dashboard/_layout/admin/_layout/settings/$namespace': typeof DashboardLayoutAdminLayoutSettingsNamespaceRoute
  '/dashboard/_layout/instance/$instance/settings/$namespace': typeof DashboardLayoutInstanceInstanceSettingsNamespaceRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/dashboard/admin'
    | '/dashboard/instance/$instance'
    | '/dashboard/user/instances'
    | '/dashboard/admin/console'
    | '/dashboard/admin/overview'
    | '/dashboard/instance/$instance/accounts'
    | '/dashboard/instance/$instance/console'
    | '/dashboard/instance/$instance/discover'
    | '/dashboard/instance/$instance/proxies'
    | '/dashboard/admin/settings/$namespace'
    | '/dashboard/instance/$instance/settings/$namespace'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/dashboard/admin'
    | '/dashboard/instance/$instance'
    | '/dashboard/user/instances'
    | '/dashboard/admin/console'
    | '/dashboard/admin/overview'
    | '/dashboard/instance/$instance/accounts'
    | '/dashboard/instance/$instance/console'
    | '/dashboard/instance/$instance/discover'
    | '/dashboard/instance/$instance/proxies'
    | '/dashboard/admin/settings/$namespace'
    | '/dashboard/instance/$instance/settings/$namespace'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/dashboard/_layout'
    | '/dashboard/_layout/admin'
    | '/dashboard/_layout/admin/_layout'
    | '/dashboard/_layout/instance/$instance'
    | '/dashboard/_layout/user/instances'
    | '/dashboard/_layout/admin/_layout/console'
    | '/dashboard/_layout/admin/_layout/overview'
    | '/dashboard/_layout/instance/$instance/accounts'
    | '/dashboard/_layout/instance/$instance/console'
    | '/dashboard/_layout/instance/$instance/discover'
    | '/dashboard/_layout/instance/$instance/proxies'
    | '/dashboard/_layout/admin/_layout/settings/$namespace'
    | '/dashboard/_layout/instance/$instance/settings/$namespace'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard",
      "children": [
        "/dashboard/_layout"
      ]
    },
    "/dashboard/_layout": {
      "filePath": "dashboard/_layout.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/_layout/admin",
        "/dashboard/_layout/instance/$instance",
        "/dashboard/_layout/user/instances"
      ]
    },
    "/dashboard/_layout/admin": {
      "filePath": "dashboard/_layout/admin",
      "parent": "/dashboard/_layout",
      "children": [
        "/dashboard/_layout/admin/_layout"
      ]
    },
    "/dashboard/_layout/admin/_layout": {
      "filePath": "dashboard/_layout/admin/_layout.tsx",
      "parent": "/dashboard/_layout/admin",
      "children": [
        "/dashboard/_layout/admin/_layout/console",
        "/dashboard/_layout/admin/_layout/overview",
        "/dashboard/_layout/admin/_layout/settings/$namespace"
      ]
    },
    "/dashboard/_layout/instance/$instance": {
      "filePath": "dashboard/_layout/instance/$instance.tsx",
      "parent": "/dashboard/_layout",
      "children": [
        "/dashboard/_layout/instance/$instance/accounts",
        "/dashboard/_layout/instance/$instance/console",
        "/dashboard/_layout/instance/$instance/discover",
        "/dashboard/_layout/instance/$instance/proxies",
        "/dashboard/_layout/instance/$instance/settings/$namespace"
      ]
    },
    "/dashboard/_layout/user/instances": {
      "filePath": "dashboard/_layout/user/instances.tsx",
      "parent": "/dashboard/_layout"
    },
    "/dashboard/_layout/admin/_layout/console": {
      "filePath": "dashboard/_layout/admin/_layout/console.tsx",
      "parent": "/dashboard/_layout/admin/_layout"
    },
    "/dashboard/_layout/admin/_layout/overview": {
      "filePath": "dashboard/_layout/admin/_layout/overview.tsx",
      "parent": "/dashboard/_layout/admin/_layout"
    },
    "/dashboard/_layout/instance/$instance/accounts": {
      "filePath": "dashboard/_layout/instance/$instance/accounts.tsx",
      "parent": "/dashboard/_layout/instance/$instance"
    },
    "/dashboard/_layout/instance/$instance/console": {
      "filePath": "dashboard/_layout/instance/$instance/console.tsx",
      "parent": "/dashboard/_layout/instance/$instance"
    },
    "/dashboard/_layout/instance/$instance/discover": {
      "filePath": "dashboard/_layout/instance/$instance/discover.tsx",
      "parent": "/dashboard/_layout/instance/$instance"
    },
    "/dashboard/_layout/instance/$instance/proxies": {
      "filePath": "dashboard/_layout/instance/$instance/proxies.tsx",
      "parent": "/dashboard/_layout/instance/$instance"
    },
    "/dashboard/_layout/admin/_layout/settings/$namespace": {
      "filePath": "dashboard/_layout/admin/_layout/settings/$namespace.tsx",
      "parent": "/dashboard/_layout/admin/_layout"
    },
    "/dashboard/_layout/instance/$instance/settings/$namespace": {
      "filePath": "dashboard/_layout/instance/$instance/settings/$namespace.tsx",
      "parent": "/dashboard/_layout/instance/$instance"
    }
  }
}
ROUTE_MANIFEST_END */
